import { combineReducers, configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import formDataReducer from './apps/form/FormDataSlice';
import DownloadReducer from './apps/downloader/DownloaderSlice';

import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    formData: formDataReducer,
    downloadData: DownloadReducer,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  formData: formDataReducer,
  downloadData: DownloadReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;
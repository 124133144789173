import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import AuthGuard from 'src/guards/authGuard/AuthGuard'
import GuestGuard from 'src/guards/authGuard/GuestGaurd'
import Loadable from '../layouts/full/shared/loadable/Loadable'

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// pages
const Overview = Loadable(lazy(() => import('../views/pages/portal/Overview')));
const Rentcard = Loadable(lazy(() => import('../views/pages/portal/Rentcard')));
const ApplicantFolder = Loadable(lazy(() => import('../views/pages/portal/ApplicantFolder')));
const UpdateFolder = Loadable(lazy(() => import('../views/pages/portal/UpdateFolder')));
const Invoice = Loadable(lazy(() => import('src/views/pages/portal/Invoice')));
const FAQ = Loadable(lazy(() => import('../views/pages/portal/FAQ')));
const PersonalDetails = Loadable(lazy(() => import('../views/pages/portal/PersonalDetails')));

// verify
const GetDocument = Loadable(lazy(() => import('../views/pages/verify/GetDocument')));

// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth/Login')));
const LoginLanding = Loadable(lazy(() => import('src/views/authentication/auth/LoginLanding')));

const Register = Loadable(lazy(() => import('../views/authentication/auth/Register')));
const ForgotPassword = Loadable(
  lazy(() => import('../views/authentication/auth/ForgotPassword')),
);
const TwoSteps = Loadable(lazy(() => import('../views/authentication/auth/TwoSteps')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

// onboarding
const Onboarding = Loadable(lazy(() => import('../views/pages/onboarding/Onboarding')));

// wg-gesucht
const WGGIntro = Loadable(lazy(() => import('../views/pages/onboarding/WGGIntro')));
const WGGOnboarding = Loadable(lazy(() => import('../views/pages/onboarding/WGGOnboarding')));

// wg-gesucht guarantor
const WGGGuarantorIntro = Loadable(lazy(() => import('../views/pages/onboarding/WGGGuarantorIntro')));
const WGGGuarantorOnboarding = Loadable(lazy(() => import('../views/pages/onboarding/WGGGuarantorOnboarding')));

// Fintiba
const FintibaIntroduction = Loadable(lazy(() => import('../views/pages/onboarding/FintibaIntro')));
const FintibaOnboarding = Loadable(lazy(() => import('../views/pages/onboarding/FintibaOnboarding')));

const Router = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/pages/overview" /> },
      { path: '/pages/overview', element: <Overview /> },
      { path: '/pages/rentcard', element: <Rentcard /> },
      { path: '/pages/applicantfolder', element: <ApplicantFolder /> },
      { path: '/pages/updatefolder', element: <UpdateFolder /> },
      { path: '/pages/invoice', element: <Invoice /> },
      { path: '/pages/faq', element: <FAQ /> },
      { path: '/pages/personaldetails', element: <PersonalDetails /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/landing', element: <LoginLanding /> },
      { path: '/auth/register', element: <Register /> },
      { path: '/auth/forgot-password', element: <ForgotPassword /> },
      { path: '/auth/two-steps', element: <TwoSteps /> },
      { path: '/auth/maintenance', element: <Maintenance /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/onboarding',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard> 
    ),
    children: [
      { path: '/onboarding', element: <Onboarding /> },
    ],
  },
  {
    path: '/verify',
    element: (
      <BlankLayout />
    ),
    children: [
      { path: '/verify/getdocument/:id', element: <GetDocument /> },
    ],
  },
  {
    path: '/wg-gesucht',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard> 
    ),
    children: [
      { path: '/wg-gesucht', element: <WGGIntro /> },
      { path: '/wg-gesucht/onboarding', element: <WGGOnboarding />}
    ],
  },
  {
    path: '/fintiba',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard> 
    ),
    children: [
      { path: '/fintiba', element: <FintibaIntroduction /> },
      { path: '/fintiba/onboarding', element: <FintibaOnboarding />}
    ],
  },
  {
    path: '/wgg-guarantor',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard> 
    ),
    children: [
      { path: '/wgg-guarantor', element: <WGGGuarantorIntro /> },
      { path: '/wgg-guarantor/onboarding', element: <WGGGuarantorOnboarding />}
    ],
  }
];

export default Router;
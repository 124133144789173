// formDataSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FurtherData {
  selfDescription?: string;
}

interface FormData {
  furtherData: FurtherData;
}

interface FormState {
  formData: FormData | null;
}

const initialState: FormState = {
  formData: null
};

const formDataSlice = createSlice({
  name: 'saveFormData',
  initialState,
  reducers: {
    saveFormData: (state, action: PayloadAction<FormData>) => {
      state.formData = action.payload;
    }
  }
});

export const { saveFormData } = formDataSlice.actions;

export default formDataSlice.reducer;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

/* import english from 'src/utils/languages/en.json';
 */
/* import french from 'src/utils/languages/fr.json';
import arabic from 'src/utils/languages/ar.json';
import chinese from 'src/utils/languages/ch.json'; */


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: false,
    fallbackLng: 'en',
    lng: 'de',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;

// downloaderSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DownloadData {
  timestamp: string; 
}

interface DownloadState {
  downloadData: DownloadData | null;
}

const initialState: DownloadState = {
  downloadData: null
};

const downloaderSlice = createSlice({
  name: 'saveDownloadData',
  initialState,
  reducers: {
    saveDownloadTimestamp: (state, action: PayloadAction<DownloadData>) => {
      state.downloadData = action.payload;
    }
  }
});

export const { saveDownloadTimestamp } = downloaderSlice.actions;

export default downloaderSlice.reducer;